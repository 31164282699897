import React from 'react'
import { Select as ANTDSelect } from 'antd'
import { SelectStyle } from '../../../style/styled/antd/Select.style'
import { SelectValue } from 'antd/lib/select'

export class Select<ValueType extends SelectValue = SelectValue> extends ANTDSelect<ValueType> {
  render() {
    return (
      <>
        <style jsx global>
          {SelectStyle}
        </style>
        <ANTDSelect {...this.props} />
      </>
    )
  }
}
